<script>
import { mapState, mapActions } from "vuex";
import NewsPreview from "@/components/NewsPreview";
import MainButton from "@/components/helpers/MainButton";
import MainLoader from "@/components/helpers/MainLoader.vue";
import TelegramChatNews from "@/components/TelegramChatNews.vue";
import TelegramChatVip from "@/components/TelegramChatVip.vue";

export default {
  components: {
    NewsPreview,
    MainButton,
    MainLoader,
    TelegramChatNews,
    TelegramChatVip,
  },

  data() {
    return {
      params: {
        page: 1,
      },
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.loadDistributorNews(this.params);
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      distributorNews: (state) => state.news.distributorNews,
      distributorNewsStatus: (state) => state.news.distributorNewsStatus,
      distributorNewsLastPage: (state) => state.news.distributorNewsLastPage,
    }),

    isShownTelegramVip() {
      return (
        this.user.tariff?.code === "vip_d" ||
        this.user.tariff?.code === "vip_gold_d"
      );
    },
  },

  methods: {
    ...mapActions({
      loadDistributorNews: "news/loadDistributorNews",
    }),
  },

  mounted() {
    this.loadDistributorNews(this.params);
  },
};
</script>

<template>
  <div class="news">
    <div class="news__telegram">
      <TelegramChatNews />

      <TelegramChatVip v-if="isShownTelegramVip" />
    </div>

    <div class="news__items">
      <NewsPreview
        v-for="news in distributorNews"
        :key="news.id"
        :news="news"
      />
    </div>

    <MainLoader
      v-if="distributorNewsStatus === 'LOADING'"
      class="news__loader"
    />

    <MainButton
      v-else-if="params.page < distributorNewsLastPage"
      class="news__btn"
      :title="'Показать еще'"
      :color="'dark'"
      @click="params.page++"
    />
  </div>
</template>

<style scoped lang="scss">
.news {
  padding: 0 $space-xxl $space-xxl;

  @media (max-width: 576px) and (min-width: 426px) {
    padding: 0 $space-xl $space-xxl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l $space-xl;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m $space-xl;
  }

  &__loader {
    max-width: 694px;
  }

  &__telegram {
    display: grid;
    grid-template-columns: repeat(auto-fill, 335px);
    grid-gap: $space-l;
    margin-bottom: 32px;

    @media (max-width: 789px) {
      grid-template-columns: 1fr;
    }
  }

  &__items {
    max-width: 694px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 335px);
    grid-gap: $space-l;

    @media (min-width: 386px) and (max-width: 789px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 385px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  &__btn {
    margin-top: $space-xxl;
    width: 150px;
  }
}

//@media (max-width: 475px) {
//  .news {
//    &__telegram {
//      grid-template-columns: repeat(1, 1fr);
//    }
//  }
//}
</style>
